import { Routes, Route } from 'react-router-dom';

import ScrollToTop from './features/ScrollToTop';

import HeaderBody from './layouts/HeaderBody';
import Homepage from './pages/Homepage';
import Presentation from './pages/Presentation';
import DesignStudies from './pages/DesignStudies';
import ExecutionStudies from './pages/ExecutionStudies';
import SiteSupervision from './pages/SiteSupervision';
import Footer from './layouts/Footer';

import './assets/styles/styles.css';
import './assets/styles/commons.css';

function App() {

	/*****************
	 *    RENDER     *
	 * ***************/

    return(
        <>
            <ScrollToTop />
            <HeaderBody />

            <main id="main-content" className='main'>
                <Routes>
                    <Route index element={<Homepage />} />
                    <Route path="/" element={<Homepage />} />
                    <Route path="/presentation" element={<Presentation />} />
                    <Route path="/etudes-de-conception" element={<DesignStudies />} />
                    <Route path="/etudes-d-execution" element={<ExecutionStudies />} />
                    <Route path="/suivi-de-chantier" element={<SiteSupervision />} />
                </Routes>
            </main>

            <Footer />
        </>
    );
}

export default App;