
/*******************
 *    VARIABLES    *
 * *****************/

const activatedClass = "activated";

/**
 * Management of menu item (color/discolor item)
 */
class MenuItemManagement {

    /*******************
	 *    FUNCTIONS    *
	 * *****************/

	/**
	 * Activate the item from event by adding the class activated on it
	 * 
	 * @param {*} itemId 
	 */
	addActivatedClass(itemId) {
		this.removeActivateClassFromAll();
		document.getElementById(itemId).classList.add(activatedClass);
	}

	/**
	 * Deactivate all items by removing the class activated
	 */
	removeActivateClassFromAll() {
		// Remove activated class from all item-primary-menu elements
		const primaryMenuItems  = document.querySelectorAll(".item-primary-menu");
		primaryMenuItems.forEach( primaryMenuItem => {
			primaryMenuItem.classList.remove(activatedClass);
		});
	}
}

// eslint-disable-next-line
export default new MenuItemManagement();