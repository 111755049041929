import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import locationIcon from '../assets/images/location-icon.png';
import emailIcon from '../assets/images/mail-icon.png';
import phoneIcon from '../assets/images/phone-icon.png';
import facebookIcon1 from '../assets/images/facebook-icon-1.jpg';
import facebookIcon2 from '../assets/images/facebook-icon-2.png';
import successIcon from '../assets/images/success-icon.png';

import SendEmail from "../features/SendEmail";
import useScrollIntoView from '../hooks/useScrollIntoView';

function ContactUs() {

	/*******************
	 *    VARIABLES    *
	 * *****************/

    const [inputFields, setInputFields] = useState({
        name : "",
        email : "",
        phone : "",
        message : ""
    });

    const [isMessageSent, setIsMessageSent] = useState(false);
    const [connectionError, setConnectionError] = useState("");
    const [errorMessages, setErrorMessages] = useState({});

    const location = useLocation();
    const sectionIdToScrollToInLocation = location!=null && location.state != null && location.state.sectionIdToScrollTo != null;

	/*******************
	 *    FUNCTIONS    *
	 * *****************/

    // Scroll to the element with this section id
    useScrollIntoView(sectionIdToScrollToInLocation, location && location.state ? location.state.sectionIdToScrollTo : "");

	/**
	 * Update data of the email message
	 */
	function updateFields(event) {
		setInputFields({ ...inputFields, [event.target.name] : event.target.value });
	}

	/*****************
	 *    RENDER     *
	 * ***************/

    return (
        <div className="grid-page after-sticky-page">
            <div id='interested-by-us' className='grid-page-content'>
                <span className='interested-by-us-main-title'>
                    Intéressé par nos services ?
                </span>

                <span className="color-navy-blue fton-size-1-1875rem">Laissez-nous un message, nous vous contacterons dès que possible :</span>

                <div id="contact-us">
                    <div id="contact-ways">
                        <span></span>
                        <span></span>

                        <div className="contact-way-icon"  id='postal-address-contact-way-icon'><img src={locationIcon} alt="Contact - Adresse postale BETIS SARL - SADA Mayotte" loading="lazy" /></div>
                        <div id='postal-address-contact-way' className="contact-way-details">137 Rue Younoussa Bamana, Quartier Comojou, 97640 Sada</div>
                        
                        <div className="contact-way-icon"><img src={emailIcon} alt="Contact - Adresse email BETIS SARL" loading="lazy" /></div>
                        <div id='mail-address-contact-way' className="contact-way-details">Betis.myt@gmail.com</div>
                        
                        <div className="contact-way-icon"><img src={phoneIcon} alt="Contact - Numéro de téléphone BETIS SARL" loading="lazy" /></div>
                        <div id='portable-contact-way' className="contact-way-details">06.93.20.82.82</div>

                        <div className="contact-way-icon fb-contact-way-icon"><Link to="https://www.facebook.com/profile.php?id=100082911247931&sk=grid" target="_blank"><img id='icon-1-facebook' src={facebookIcon1} alt="Contact - Facebook BETIS SARL" loading="lazy" /><img id='icon-2-facebook' src={facebookIcon2} alt="Contact - Facebook BETIS SARL" loading="lazy" /></Link></div>
                        <div id='fb-contact-way' className="contact-way-details">
                            <Link to="https://www.facebook.com/profile.php?id=100082911247931&sk=grid" target="_blank">Retrouvez-nous également sur Facebook</Link>
                        </div>

                        <span></span>
                        <span></span>
                    </div>

                    <div id='sending-message'>
                        { isMessageSent && !connectionError ?
                            (<div id='box-message'><div id="sent-message-status"><span>Votre message a été envoyé avec succès </span><img src={successIcon} alt="Succès envoi du mail" loading="lazy" /></div></div>)
                            :
                            (
                                <>
                                    { connectionError ?
                                        (
                                            <div id='box-message'>
                                                <span></span> 
                                                <div id="sent-message-status">{connectionError}</div>
                                                <button onClick={() => setConnectionError("")}>R&eacute;essayer</button>
                                            </div>
                                        )
                                        :
                                        (
                                            <form id='form-sending-message' method='post' onSubmit={(event) => SendEmail(event, setErrorMessages, setIsMessageSent, setConnectionError, inputFields)}>
                                                <div>
                                                    <div className='float-left'>
                                                        Votre nom <span className='color-red'>*</span>
                                                    </div>
                                                    <div className='input-field-error-message'>{errorMessages.name ? (<>{errorMessages.name}</>):(<></>)}</div>
                                                </div>
                                                <input type="text" name="name" value={inputFields.name} onChange={(event) => updateFields(event)}></input>
                                                <div><div className='float-left'>Adresse e-mail <span className='color-red'>*</span></div><div className='input-field-error-message'>{errorMessages.email ? (<>{errorMessages.email}</>):(<></>)}</div></div>
                                                <input type="email" name="email" value={inputFields.email} onChange={(event) => updateFields(event)}></input>
                                                <div><div className='float-left'>Téléphone <span className='color-red'>*</span></div><div className='input-field-error-message'>{errorMessages.phone ? (<>{errorMessages.phone}</>):(<></>)}</div></div>
                                                <input type="text" name="phone" value={inputFields.phone} onChange={(event) => updateFields(event)}></input>
                                                <div><div className='float-left'>Votre message <span className='color-red'>*</span></div><div className='input-field-error-message'>{errorMessages.message ? (<>{errorMessages.message}</>):(<></>)}</div></div>
                                                <textarea name="message" value={inputFields.message} onChange={(event) => updateFields(event)} rows={6} cols={40} />
                                                <button id='button-form-sending-message' type="submit">Envoyer</button>
                                            </form>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>

                <div></div>
                
            </div>
        </div>
    );
}

export default ContactUs;