/**
 * Validate the input fields data
 * @param {*} inputFields 
 * @returns error list
 */
function ValidateInputFields(inputFields) {
    let errors= {};
    if(!inputFields.name) {
        errors.name = "Le nom est obligatoire";
    }
    if(!inputFields.email) {
        errors.email = "L'adresse e-mail est obligatoire";
    }
    if(!inputFields.phone) {
        errors.phone =  "Le numéro de téléphone est obligatoire";
    }
    if(!inputFields.message) {
        errors.message =  "Un message doit être rédigé";
    }

    return errors;
}

export default ValidateInputFields;