import ValidateInputFields from "./ValidateInputFields";

/**
 * Call the back-end API to send the email
 * Using async await to be sure the fetch call has been completed (before doing next steps)
 * @param {*} event 
 * @returns 
 */
async function SendEmail(event, setErrorMessages, setIsMessageSent, setConnectionError, inputFields) {
    // Prevent the browser from reloading the page
    event.preventDefault();

    // Reinitialize error messages to empty
    setErrorMessages({});
    // Validate input fields
    const errors = ValidateInputFields(inputFields);
    // Update input field errors if there are
    setErrorMessages(errors);

    // In case there is not error, prepare data to send to back-end
    if(Object.keys(errors).length === 0) {
        // Read the form data
        const messageForm = event.target;
        const messageFormData = new FormData(messageForm);

        // Working with message form data as a plain object:
        const messageFormJson = Object.fromEntries(messageFormData.entries());

        try {
            // Send data to back-end for email sending
            const response = await fetch('/api/emails', 
                                        {
                                            method : "POST",
                                            body : JSON.stringify(messageFormJson),
                                            headers : { "Content-type": "application/json; charset=UTF-8"}
                                        }
                                    );
            // Wait and get the result of back-end
            const data = await response.text();
            // If email was sent successfully notify it
            if(response.status === 200 & data === "Ok") {
                setIsMessageSent(true);
                return response.statusText;
            }
            // Otherwise notify the failure
            else {
                setIsMessageSent(false);
                setConnectionError("Un problème de connexion est survenu lors de l'envoi de votre message. Vérifiez votre connexion et réessayez.");
            }
        } catch (error) {
            console.log(error.message);
        }
    }
}

export default SendEmail;