import OrganizationalChart from "./OrganizationalChart";
import MetaTags from "../components/MetaTags";
import useActivateItem from "../hooks/useActivateItem";
import { Link } from "react-router-dom";

function Presentation() {

    // Activate item item-presentation
    useActivateItem("item-presentation");

	/*****************
	 *    RENDER     *
	 * ***************/

    return (
        <>
            <MetaTags title="Présentation BETIS SARL - SADA Mayotte - Expertise en Fluides, Thermique et Electricité" description="Tirez profit de l'expertise de BETIS en Fluides/Thermique et Electricité, Etude/Suivi/Direction des travaux dans l'Électrification Rurale" currentPageURL="https://betis.yt/presentation" />
            <div id="wrapper-main-title-area">
                <span></span>
                <div id="main-title-area">
                    <span></span>
                    <span id="main-title">PRESENTATION DE L&apos;ENTREPRISE</span>
                    <span></span>
                </div>
                <span></span>
            </div>

            <div className="grid-page presentation-sticky sticky-page">
                <div id="presentation-enterprise-specialisation" className="grid-page-content">
                    <span></span>
                    <div>
                        <span className="light-blue font-weight-bold">BETIS SARL</span> est une société spécialisée dans le dessin des lots 
                        techniques : <span className="light-blue font-weight-bold">Fluides, Thermique</span> et <span className="light-blue font-weight-bold">Electricité</span> 
                        <span>&nbsp;ainsi que l&apos;étude, le <Link className="text-decoration-none font-weight-bold" to="/suivi-de-chantier">suivi et la direction de travaux</Link> dans le domaine de l&apos;Électrification Rurale.</span>
                    </div>

                    <span>Il s&apos;agit d&apos;une structure créée à Mayotte en 2022.</span>

                    <span>BETIS apporte le plus grand soin à répondre aux attentes de ses clients.</span>

                    <div id="contact-details">
                        <Link to="/" state={{ sectionIdToScrollTo: "interested-by-us" }}>Contactez-nous :</Link>
                        <div></div>
                        <div>Téléphone : 06.93.20.82.82</div>
                        <div>Courriel : <span className="color-white">betis.myt@gmail.com</span></div>
                        <div>Adresse : 137 Rue Younoussa Bamana, Quartier Comojou, 97640 Sada</div>
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="colour-gradient-progressively-opened-box grid-page after-sticky-page">
                <div id="presentation-legal-info-expertise" className="grid-page-content">
                    <span></span>
                    <div className="presentation-legal-info">
                        <span className="presentation-legal-info-title-bullet"></span><span className="presentation-legal-info-title">Forme Juridique</span>
                        <span></span><span>Société commerciale à responsabilité limitée (SARL).</span>
                    </div>

                    <div className="presentation-legal-info">
                        <span className="presentation-legal-info-title-bullet"></span><span className="presentation-legal-info-title">Identifiant SIREN / SIRET</span>
                        <span></span><span>SIREN : 911 644 045</span>
                        <span></span><span>SIRET : 911 644 045 00013</span>
                    </div>

                    <div className="presentation-legal-info">
                        <span className="presentation-legal-info-title-bullet"></span><span className="presentation-legal-info-title">APE (Activité Principale Exercée)</span>
                        <span></span><span>CODE APE : <b>7112B </b></span>
                    </div>

                    <div>
                        Dessin d&apos;études techniques bâtiment second oeuvre et Génie civil dans le domaine VRD (Voirie Réseaux Divers) 
                        et <Link className="text-decoration-none font-weight-bold" to="/suivi-de-chantier">Direction Exécution Travaux</Link>.
                    </div>

                    <div className="title-2">EXPERTISE EN FLUIDES/THERMIQUE/ENERGIE</div>

                    <div id="presentation-job-domain-list">
                        <span>Notre métier consiste à accompagner le maître d&apos;ouvrage dans la <Link className="text-decoration-none font-weight-bold" to="/etudes-de-conception">réalisation des plans de conception</Link> et/ou <Link className="text-decoration-none font-weight-bold" to="/etudes-d-execution">exécution</Link> de ses projets dans les domaines suivants : </span>
                        <ul> 
                            <li>Installations techniques électriques et fluides des bâtiments dans plusieurs secteurs (logement, tertiaire, Etablissement recevant du public ou autres)</li>
                            <li>Des réseaux aériens et souterrains de distribution d&apos;électricité (Electrification rurale)</li>
                            <li>Direction exécution travaux</li>
                            <li>Des réseaux d&apos;Eclairage public</li>
                        </ul>
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <OrganizationalChart />
        </>
    );
}

export default Presentation;