import { useEffect } from "react";
import MenuItemManagement from "../features/MenuItemManagement";

/**
 * Color the item with idItem as active item by adding activated item class name
 * @param {*} idItem 
 */
function useActivateItem(idItem) {

    useEffect(() => {
        MenuItemManagement.addActivatedClass(idItem);
    }, [idItem]);

}

export default useActivateItem;