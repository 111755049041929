import MetaTags from '../components/MetaTags';
import useActivateItem from '../hooks/useActivateItem';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';

function Homepage() {

    //Color the item "Accueil" as active item by activate the item-homepage
    useActivateItem("item-homepage");

    /*****************
	 *    RENDER     *
	 * ***************/

    return(
        <>
            <MetaTags title="BETIS - Bureau d'Etudes Techniques Ingénieurie & Suivi - SADA Mayotte" description="Bénéficiez de notre service fiable de bureau d'étude technique dans le domaine du fluide & Electricité" currentPageURL="https://betis.yt/" />
            <AboutUs />
            <ContactUs />
        </>
    );
}

export default Homepage;