import { Link } from "react-router-dom";

import facebookIcon1 from '../assets/images/facebook-icon-1.jpg';
import facebookIcon2 from '../assets/images/facebook-icon-2.png';

function Footer() {
    return(
        <footer id="footer">
            <div id="footer-space-before"> </div>

            <div id="footer-content" >
                <span></span><span></span><span></span><span></span>

                <div id='footer-logo-betis'></div>

                <div id='footer-company'>
                    <span className='footer-column-title'>L'entreprise</span>
                    <span></span>
                    <Link to="/presentation">A propos de nous</Link>
                    <Link to="/">Notre métier</Link>
                    <Link to="/" state={{ sectionIdToScrollTo: "interested-by-us" }}>Contact</Link>
                </div>

                <div id='footer-services'>
                    <span className='footer-column-title'>Services</span>
                    <span></span>
                    <Link to="/etudes-de-conception">Conception projet de construction</Link>
                    <Link to="/etudes-d-execution">Réalisation plans d'exécution des travaux</Link>
                    <Link to="/suivi-de-chantier">Suivi & direction des travaux</Link>
                </div>

                <span></span>
                
                <span></span>
                <span></span>

                <div id='footer-betis-social-networks' className="fb-contact-way-icon">
                    <Link to="https://www.facebook.com/profile.php?id=100082911247931&sk=grid" target="_blank">
                        <img id='icon-1-facebook' src={facebookIcon1} alt="Contact - Facebook BETIS SARL" loading="lazy" />
                        <img id='icon-2-facebook' src={facebookIcon2} alt="Contact - Facebook BETIS SARL" loading="lazy" />
                    </Link>
                </div>
            </div>
		</footer>
    )
}

export default Footer;