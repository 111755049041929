import { Link } from 'react-router-dom';

import betisLogo from '../assets/images/Betis-logo.png';
import Button from '../components/buttons/Button';
import PanelDisplay from '../features/PanelDisplay';

import iconCancel from "../assets/images/icon-cancel.png";
import MenuItemManagement from '../features/MenuItemManagement';

function HeaderBody() {

	/*****************
	 *    RENDER     *
	 * ***************/

    return (
        <header>
            <div id="body-header" className='fixed-block'>
                <div id='logo-betis-header'>
                    <Link to={"/"}><img src={betisLogo} alt="Logo Betis" loading="lazy" onClick={() => MenuItemManagement.addActivatedClass("item-homepage")} /></Link>
                </div>

                <div id='navigation-bar' className='navigation'>
                    <div id="primary-navigation">
						<div id='wrap-button-cancel-primary-menu'>
							<Button id="button-cancel" children={<img className="icon-cancel" src={iconCancel} alt="Cancel menu" loading="lazy" />} onClick= {() => { PanelDisplay.hidePrimaryMenu() } } />
							<ul id="primary-menu">
								<li><Link id='item-homepage' className='item-primary-menu' to="/" onClick={() => {MenuItemManagement.addActivatedClass("item-homepage"); PanelDisplay.hidePrimaryMenu();}}>Accueil</Link></li>
								<li><Link id='item-presentation' className='item-primary-menu' to="/presentation" onClick={() => {MenuItemManagement.addActivatedClass("item-presentation"); PanelDisplay.hidePrimaryMenu();}}>Présentation</Link></li>
								<li><Link id='item-design-studies' className='item-primary-menu' to="/etudes-de-conception" onClick={() => {MenuItemManagement.addActivatedClass("item-design-studies"); PanelDisplay.hidePrimaryMenu();}}>Etudes de conception</Link></li>
								<li><Link id='item-execution-studies' className='item-primary-menu' to="/etudes-d-execution" onClick={() => {MenuItemManagement.addActivatedClass("item-execution-studies"); PanelDisplay.hidePrimaryMenu();}}>Etudes d'exécution</Link></li>
								<li><Link id='item-site-supervision' className='item-primary-menu' to="/suivi-de-chantier" onClick={() => {MenuItemManagement.addActivatedClass("item-site-supervision"); PanelDisplay.hidePrimaryMenu();}}>Suivi de chantier</Link></li>
							</ul>
						</div>
                    </div>
					<div id='icon-navigation'><Button children="&#9776;" onClick= {() => PanelDisplay.displayPrimaryMenu()} /></div>
                </div>
                </div>
      	</header>
    );

}

export default HeaderBody;