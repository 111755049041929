import { useEffect } from "react";
import ScrollToSectionId from "../features/ScrollToSectionId";

/**
 * Scroll (Jump) to the element with id sectionId
 * @param {*} sectionIdToScrollToInLocation 
 * @param {*} sectionId 
 */
function useScrollIntoView(sectionIdToScrollToInLocation, sectionId) {

    useEffect(() => 
        {
            if(sectionIdToScrollToInLocation){
                ScrollToSectionId(sectionId);
            }
        },[sectionIdToScrollToInLocation, sectionId]
    );

}

export default useScrollIntoView;