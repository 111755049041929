import { Link } from "react-router-dom";

import CVManager from '../assets/documents/CV-B-Issoufi.pdf';
import pdfIcon from '../assets/images/pdf-icon.png';

function OrganizationalChart() {
    return (
        <div className="grid-page after-sticky-page">
            <div id="organizational-chart" className="grid-page-content width-50percent">
                <div></div>
                <div className="title-2 text-decoration-underline">ORGANIGRAMME DE LA SOCI&Eacute;T&Eacute; BETIS</div>
                <div id="organizational-chart-content">
                    <span className="font-size-3-125rem font-weight-bold">Organigramme</span>
                    <div id="organizational-chart-main">
                        <div id="organizational-chart-manager">BRAHIM ISSOUFI</div>
                        <div className="organizational-chart-links-between-manager-employees">
                            <div className="down-arrow">
                                <div className="vertical-line"></div>
                                <div className="down-arrow-head"></div>
                            </div>
                            <div className="down-arrow margin-0-0-0-4-375rem">
                                <div className="vertical-line"></div>
                                <div className="down-arrow-head"></div>
                            </div>
                        </div>
                        <div className="organizational-chart-employees">
                            <div className="organizational-chart-employee-1">BACAR Raphael</div>
                            <div className="organizational-chart-employee-2">BAMANA Dine</div>
                        </div>
                    </div>
                </div>
                <div></div>
                <div id="organizational-chart-complement">
                    <div className="color-blue"><span className="font-weight-bold">BRAHIM ISSOUFI</span> : <span className="font-weight-bold">Gérant</span> et <span className="font-weight-bold"> chargé d&apos;affaires</span> réseaux BT/HTA & Fluides</div>
                    <div>BOINAMZE RAPHAEL : <span className="font-weight-bold">Chargé d&apos;études</span> réseaux BT/HTA </div>
                    <div>BAMANA DINE: <span className="font-weight-bold">Chargé de suivi des travaux</span> réseaux BT/HTA</div>
                </div>
                <div id="cv-manager"><Link to={CVManager} target="_blank"><span className="font-weight-bold">Curriculum Vitae du gérant</span></Link> : <Link to={CVManager} target="_blank"><img src={pdfIcon} alt="Curriculum Vitae du gérant BRAHIM Issoufi" loading="lazy" /></Link></div>

                <div></div>
            </div>
        </div>
    );
}

export default OrganizationalChart;