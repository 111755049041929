import { Link } from "react-router-dom";

function AboutUs() {

	/*****************
	 *    RENDER     *
	 * ***************/

    return (
        <>
            <div id="wrapper-main-title-area">
                <span></span>
                <div id="main-title-area">
                    <span></span>
                    <span id="main-title">QUI SOMMES-NOUS ?</span>
                    <span></span>
                </div>
                <span></span>
            </div>

            <div className="grid-page aboutus-portrait aboutus-landscape sticky-page">
                <div id="who-we-are" className="grid-page-content">
                    <span></span>
                    <span className="background-color-very-light-grey"><Link className="text-decoration-none" to="/presentation"><span className="light-blue font-weight-bold font-size-1-125rem">BETIS SARL</span></Link> s&apos;engage à offrir un service fiable de bureau d&apos;étude technique dans le domaine du fluide & Electricité.</span>
                    <div>
                        <span>Votre technicien d&apos;études vous accompagne pour :</span>
                        <ul id="homepage-technician-tasks-list">
                            <li><span>&#x2022;</span><span>Votre <Link className="text-decoration-none font-weight-bold" to="/etudes-de-conception" state={{ sectionIdToScrollTo: "electricity-network-connection" }}>raccordement au réseau EDM</Link> de Mayotte (Collectif ou Individuelle)</span></li>
                            <li><span>&#x2022;</span><span>La <Link className="text-decoration-none font-weight-bold" to="/etudes-de-conception" state={{ sectionIdToScrollTo: "electricity-network-connection" }}>réalisation de votre plan Article 323.25</Link> en collaboration avec les services d&apos;EDM (NFC 14-100, NFC11-001, NFC 11201)</span></li>
                            <li><span>&#x2022;</span><span>La <Link className="text-decoration-none font-weight-bold" to="/etudes-d-execution">réalisation de vos plans exécution travaux</Link> Fluides & Électricité (NFC 15-100)</span></li>
                            <li><span>&#x2022;</span><span>La <Link className="text-decoration-none font-weight-bold" to="/etudes-d-execution">réalisation de vos plans exécution travaux</Link> Éclairage Public (NFC 17-200)</span></li>
                            <li><span>&#x2022;</span><span>Le <Link className="text-decoration-none font-weight-bold" to="/suivi-de-chantier">suivi de vos chantiers</Link> avec la réalisation de Comptes rendus hebdomadaire</span></li>
                        </ul>
                    </div>
                    <span></span>
                </div>
            </div>
        </>
    );
}

export default AboutUs;