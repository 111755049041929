import { useLocation } from "react-router-dom";

import buildingPermitRequest from "../assets/images/Building-permit-request.png";
import buildingPlan2D from "../assets/images/Building-plan-2D.png";
import buildingPlan3D from "../assets/images/Building-plan-3D.png";
import betisDesignOfficeSupport from "../assets/images/Betis-design-office-support.png";
import workPlans from "../assets/images/work-plans.png";
import workPlans1_500 from "../assets/images/work-plans-1-500.png";
import edmNetworkLegend from "../assets/images/edm-network-legend.png";
import MetaTags from "../components/MetaTags";
import useActivateItem from "../hooks/useActivateItem";
import useScrollIntoView from "../hooks/useScrollIntoView";

function DesignStudies() {

	/*******************
	 *    VARIABLES    *
	 * *****************/

    const location = useLocation();
    const sectionIdToScrollToInLocation = location!=null && location.state != null && location.state.sectionIdToScrollTo != null;

    /*******************
	 *    FUNCTIONS    *
	 * *****************/

    // Activate item item-design-studies
    useActivateItem("item-design-studies");

    // Scroll to the element with this section id
    useScrollIntoView(sectionIdToScrollToInLocation, location && location.state ? location.state.sectionIdToScrollTo : "");

	/*****************
	 *    RENDER     *
	 * ***************/

    return(
        <>
            <MetaTags title="Etudes de conception de votre projet de constrcution: Permis de construire, Plan 2D, 3D..." description="Exploitez nos services d'études de conception dans votre projet de constrcution: Permis de construire, Plan 2D, Plan 3D, Raccordement collectif au réseau EDM, plan Article R323-25, compteur EDM, certificat de conformité CONSUEL" currentPageURL="https://betis.yt/etudes-de-conception" />
            <div id="wrapper-main-title-area">
                <span></span>
                <div id="main-title-area">
                    <span></span>
                    <div id="wrapper-main-title-and-complement">
                        <span id="main-title">VOUS CONSTRUISEZ ?</span>
                        <span className="main-title-complement">
                            VOTRE TECHNICIEN D&apos;ÉTUDES VOUS PROPOSE DE VOUS ACCOMPAGNER 
                            DANS VOTRE PROJET
                        </span>
                    </div>
                    <span></span>
                </div>
                <span></span>
            </div>

            <div className="grid-page design-sticky sticky-page top-0">
                <div id="design-study-building-permit" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#10070;</span><span>CONCEPTION:</span>
                    
                        <span>&#x2022;</span><span>PERMIS DE CONSTRUIRE (moins de 150m²)</span>
                    </div>

                    <span>Vous souhaitez construire un bâtiment ou faire des travaux sur une construction existante ? Avant de commencer vos
                        travaux, vous devez obtenir <span className="typography-key-word">un permis de construire</span> (PC).
                    </span>

                    <span>
                        Un permis de construire (PC) est une <span className="typography-key-word">autorisation d&apos;urbanisme</span> qui concerne la modification ou la réalisation d&apos;une
                        construction. Ce document est délivré par la mairie de la commune où est prévue la réalisation du projet.
                    </span>
                    <span>
                        Notre bureau d&apos;études vous propose de réaliser votre PC de moins de 150 m².
                    </span>
                    <div id="building-permit-request-image" className="study-image-example">
                        <img src={buildingPermitRequest} alt="Demande de permis de construire" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>
            
            <div className="grid-page after-sticky-page design-sticky sticky-page top-0">
                <div id="building-plan-2D" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#x2022;</span><span>PLAN DE CONSTRUCTION 2D</span>
                    </div>
                    <span>
                        <span className="typography-key-word">Les plans de maison 2D</span> offrent une vue en plan simple sur un bien. Ils illustrent clairement la configuration d&apos;un bien et
                        sont simples à comprendre. En règle générale, un plan de maison 2D est en noir et blanc ou présente un code couleur, par
                        exemple du vert pour signaler le jardin, et du marron pour indiquer la terrasse extérieure.
                    </span>
                    
                    <div id="building-plan-2D-image" className="study-image-example">
                        <img src={buildingPlan2D} alt="Plan de construction 2D" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>
            
            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box">
                <div id="building-plan-3D" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#x2022;</span><span>PLAN DE CONSTRUCTION 3D</span>
                    </div>
                    <span>
                        <span className="typography-key-word">Un plan de maison 3D</span> est un dessin généré en 3D qui illustre la configuration d&apos;un bien. Il présente en détail
                        l&apos;aménagement, le mobilier et les accessoires en 3D, ainsi que les revêtements de sols et les matériaux. La visualisation en
                        3D permet de donner vie à votre projet et de découvrir votre nouvel espace de manière plus réaliste.
                    </span>
                    <div id="building-plan-3D-image" className="study-image-example">
                        <img src={buildingPlan3D} alt="Plan de construction 3D" loading="lazy" />
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>
            
            <div className="grid-page after-sticky-page design-sticky sticky-page">
                <div id="electricity-network-connection" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#x2022;</span><span>RACCORDMENT DE VOTRE COLLECTIF AU R&Eacute;SEAU EDM &#8594; LE PLAN ARTICLE R323.25</span>
                    </div>

                    <div className="box-title-3">IMMEUBLE COLLECTIF = Plus de 2 logements dans une même parcelle</div>

                    <div className="presentation-little box-title-4">CHOIX D&apos;UN BUREAU D&apos;ETUDE</div>
                    <div>
                        Le client choisi un BET spécialisé qui sera chargé de créer les plans de travaux de l&apos;Article R323-25, en collaboration avec les
                        services d&apos;EDM (NFC 14-100, NFC 11-001, NFC 11-201).
                    </div>

                    <div className="presentation-little box-title-4">DIFFUSION DE L&apos;ARTICLE R323-25</div>
                    <div>
                        Ce <span className="typography-key-word">plan Article R323-25</span> est le <span className="typography-key-word">PERMIS DE CONSTRUIRE ELECTRICITE</span> doit être validé par le concessionnaire EDM, et envoyé
                        à l&apos;ensemble des interlocuteurs techniques et administratifs de Mayotte. (CDM, DEAL, MAIRIE, SMAE, SIEAM, ORANGE
                        TELECOM, etc.). Après 21 jours, sans réponse ou demande de modification, de la part des services consultés, les travaux
                        pourront débuter.
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page design-sticky sticky-page">
                <div id="company-choice" className="grid-page-content">
                    <span></span>
                    <span>
                        <span className="typography-key-word">EDM</span> est le concessionnaire du réseau de Distribution Public du département de Mayotte. EDM accompagne le client
                        durant toute la durée de son chantier s&apos;il le souhaite, pour réussir ensemble à ce que le raccordement de son chantier
                        se fasse dans les meilleures conditions techniques.
                    </span>

                    <div className="box-title-4 width-35percent">CHOIX D&apos;UNE ENTREPRISE POUR LA REALISATION DES TRAVAUX</div>
                    <span>
                        Selon les devis effectués, le client choisi <span className="typography-key-word">une entreprise spécialisée</span> dans les raccordements au réseau public d&apos;électricité,
                        pour réaliser les travaux dans les règles de l&apos;art, dans le respect des normes en vigueur.
                        Un géo-référencement du réseau construit devra être fourni à la fin du chantier par l&apos;entreprise réalisatrice.
                    </span>

                    <div className="box-title-4 width-35percent">CHOIX D&apos;UNE ENTREPRISE POUR LA REALISATION DES TRAVAUX</div>
                    <span>
                        L&apos;entreprise du client contacte EDM, pour définir un rendez-vous et donne les documents adéquats qui attestent <span className="typography-key-word">la bonne </span>
                        <span className="typography-key-word">réalisation des travaux</span>, et de leur garantie dans le temps. Après le contrôle du chargé d&apos;affaire EDM, les documents sont
                        validés et transmis au service Clientèle, pour le devis EDM et la programmation de la pose des compteurs ; et aussi
                        transmis au service Exploitation pour raccorder et faire la <span className="typography-key-word">mise sous tension de l&apos;installation électrique collective</span> avec
                        l&apos;entreprise du client.
                    </span>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box">
                <div id="tenant-entry" className="grid-page-content">
                    <span></span>
                    <div className="presentation-little box-title-4 width-14percent">ENTREE DES LOCATAIRES</div>
                    <span>
                        Ne pas oublier que <span className="typography-key-word">la mise en service de chaque compteur EDM</span> ne se fera qu&apos;avec la fourniture d&apos;un <span className="typography-key-word">certificat de </span>
                        <span className="typography-key-word">conformité CONSUEL</span> qui atteste de la sécurité de l&apos;installation intérieur (NFC 15-100).
                    </span>

                    <div id="betis-design-office-support-image" className="study-image-example width-50percent">
                        <img src={betisDesignOfficeSupport} alt="Réalisation plans travaux BETIS Article R.323-25" loading="lazy" />
                    </div>

                    <div className="box-important-information">PRINCIPAUX CLIENTS : PARTICULIERS ET COMMUNES</div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <div className="grid-page after-sticky-page design-sticky sticky-page">
                <div id="design-studies-network-extension" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#10070;</span><span>CONCEPTION:</span>
                        <span>&#x2022;</span><span>EXTENSION RÉSEAU HTA/BT PUBLIC &#8594; LE PLAN ARTICLE R323.25</span>
                    </div>
                    <ol type="A">
                        <li><span className="font-weight-bold">PHASE PRÉ-ÉTUDE</span>
                            <ol type="1">
                                <li>Contact avec la Commune demandeur</li>
                                <li>Déclaration des travaux (DT)</li>
                                <li>Réalisation de la Pré-étude.</li>
                                <li>Transmission de la pré-étude à EDM.</li>
                            </ol>
                        </li>
                        <li><span className="font-weight-bold">PHASE APD</span>
                            <ol type="1">
                                <li>Relevé Topographique</li>
                                <li>Réalisation de l&apos;Avant projet définitif (APD)</li>
                                <li>Transmission de l&apos;APD au MOA et EDM.</li>
                            </ol> 
                        </li>
                        <li><span className="font-weight-bold">PHASE ARTICLE R323.25</span>
                            <br />
                            L&apos;Article a pour objet:
                            <ul>
                                <li>la validation définitive de la solution technique proposée à l&apos;APD</li>
                                <li>la numérotation définitive des équipements à poser</li>
                            </ul>
                            Les conventions de passage ou de poste seront transmises aux personnes concernées pour signature et validation, ces conventions accompagneront la
                            <br />remise du plan Article.
                        </li>
                    </ol>

                    <div className="box-important-information width-40percent">PRINCIPAUX CLIENTS : CONSEIL DÉPARTEMENTAL DE MAYOTTE ET EDM</div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page design-sticky sticky-page">
                <div id="work-plans" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example width-75percent justify-self-left">
                        <img src={workPlans} alt="Plans de travaux 1/200" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box">
                <div id="work-plans-1-500" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example width-75percent justify-self-right">
                        <img src={workPlans1_500} alt="Plans de travaux 1/500" loading="lazy" />
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <div className="grid-page after-sticky-page design-sticky sticky-page">
                <div id="edm-network-legend" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example justify-self-left">
                        <img src={edmNetworkLegend} alt="Légende reseau edm" loading="lazy" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DesignStudies;