class PanelDisplay {

	/**
	 * Display primary menu navigation
	 */
    displayPrimaryMenu() {
		if (document.getElementById("navigation-bar").className === "navigation") {
			document.getElementById("navigation-bar").className += " menu-displayed";
		}
	}

	/**
	 * Hide primary menu navigation
	 */
	hidePrimaryMenu() {
        document.getElementById("navigation-bar").className = "navigation";
	}

}

// eslint-disable-next-line
export default new PanelDisplay();