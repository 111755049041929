import siteSupervision from "../assets/images/Site-supervision.png";
import siteSupervision2 from "../assets/images/Site-supervision-2.png";
import siteSupervision3 from "../assets/images/Site-supervision-3.png";
import MetaTags from "../components/MetaTags";
import useActivateItem from "../hooks/useActivateItem";

function SiteSupervision() {

    // Activate item item-site-supervision
    useActivateItem("item-site-supervision");

	/*****************
	 *    RENDER     *
	 * ***************/

    return (
        <>
            <MetaTags title="Suivi de l'exécution de vos travaux et transmission des compte-rendus" description="Tirez avantage de notre expertise dans le suivi d'exécution de vos travaux. Nous vous transmettons les compte-rendus réalisés sous ARCHIPAD" currentPageURL="https://betis.yt/suivi-de-chantier" />
            <div id="wrapper-main-title-area">
                <span></span>
                <div id="main-title-area">
                    <span></span>
                    <div id="wrapper-main-title-and-complement">
                        <span id="main-title">VOUS DEVEZ SUIVRE VOS TRAVAUX ?</span>
                        <span className="main-title-complement">
                            VOTRE TECHNICIEN D&apos;&Eacute;TUDES VOUS PROPOSE DE SUIVRE L&apos;EX&Eacute;CUTION 
                            DE VOS TRAVAUX ET VOUS TRANSMETTRE DES COMPTES-RENDUS
                        </span>
                    </div>
                    <span></span>
                </div>
                <span></span>
            </div>

            <div className="grid-page supervision-sticky sticky-page">
                <div id="site-supervision-execution-direction-goals-software" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#10070;</span><span>DIRECTION EX&Eacute;CUTION DES TRAVAUX:</span>
                    </div>
                    <div id="execution-direction-goals">
                        <span>La direction de l&apos;ex&eacute;cution du ou des contrats de travaux a pour objet de s&apos;assurer que les documents d&apos;ex&eacute;cution
                            ainsi que les ouvrages en cours de r&eacute;alisation respectent les &eacute;tudes effectu&eacute;es et organiser les travaux de fa&ccedil;on &agrave;
                            maitriser les co&ucirc;ts et d&eacute;lais.
                        </span>
                        <span>Le MOE (Ma&icirc;tre d&apos;œuvre) organise et participe aux r&eacute;unions hebdomadaires pendant toute la dur&eacute;e des travaux, Il
                            &eacute;tablira les proc&egrave;s verbaux de ces r&eacute;unions hebdomadaires et des visites de contr&ocirc;le n&eacute;cessaires ou al&eacute;atoires. Ces
                            rapports seront diffus&eacute;s &agrave; l&apos;Entreprise, au MOA (Ma&icirc;tre d&apos;ouvrage) et &agrave; EDM.</span>
                        <span>Apr&egrave;s la fin des travaux, le MOE organisera les op&eacute;rations pr&eacute;alables &agrave; la r&eacute;ception des travaux.</span>
                    </div>
                    <div id="site-supervision-software">
                        <span></span><span></span>
                        <div>&#x2022;</div><div className="font-weight-bold text-decoration-underline">Logiciels:</div>
                        <div></div><div>&Eacute;laboration des <span className="typography-key-word">Comptes-rendus</span> sous <span className="typography-key-word">ARCHIPAD</span>.</div>
                        <span></span><span></span>
                    </div>

                    <div className="box-important-information width-50percent">PRINCIPAUX CLIENTS : CONSEIL DÉPARTEMENTAL ET COMMUNES</div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box">
                <div id="site-supervision-example" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example width-70percent">
                        <img src={siteSupervision} alt="Suivi de chantier" loading="lazy" />
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <div className="grid-page after-sticky-page supervision-sticky-image sticky-page">
                <div id="site-supervision-example-2" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example width-70percent justify-self-left">
                        <img src={siteSupervision2} alt="Suivi de chantier" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page">
                <div id="site-supervision-example-3" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example width-70percent justify-self-right">
                        <img src={siteSupervision3} alt="Suivi de chantier" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>
        </>
    );
}

export default SiteSupervision;