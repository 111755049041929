import PublicLightingExecutionPlan from "../assets/images/Public-lighting-execution-plan.png";
import ElectricityLotExecutionPlan from "../assets/images/Electricity-lot-execution-plan.png";
import ElectricityLotExecutionPlan2 from "../assets/images/Electricity-lot-execution-plan-2.png";
import ElectricityLotExecutionPlan3 from "../assets/images/Electricity-lot-execution-plan-3.png";
import ElectricityLotExecutionPlan4 from "../assets/images/Electricity-lot-execution-plan-4.png";
import NFC14100CalculsRules from "../assets/images/NF-C-14100-calculs-rules.png";
import NetworkExecutionPlan from "../assets/images/network-execution-plan.png";
import MetaTags from "../components/MetaTags";
import useActivateItem from "../hooks/useActivateItem";

function ExecutionStudies() {

    // Activate item item-execution-studies
    useActivateItem("item-execution-studies");

	/*****************
	 *    RENDER     *
	 * ***************/
    
    return(
        <>
            <MetaTags title="Etudes des plans d'exécution de vos travaux: Bâtiment, Lot électricité, éclairage public..." description="Exploitez nos services d'études des plans d'exécution dans vos travaux: Etudes techniques bâtiment, lot électricité, éclairage public, Normes NF C14-100 et NF C15-100,  plans d'implantation AUTOCAD, notes de Calcul BT + Schéma Unifilaires ECODIAL, note d'éclairage DIALUX EVO" currentPageURL="https://betis.yt/etudes-d-execution" />
            <div id="wrapper-main-title-area">
                <span></span>
                <div id="main-title-area">
                    <span></span>
                    <div id="wrapper-main-title-and-complement">
                        <span id="main-title">VOUS DEVEZ R&Eacute;ALISER VOS TRAVAUX ?</span>
                        <span className="main-title-complement">
                            VOTRE TECHNICIEN D&apos;&Eacute;TUDES VOUS PROPOSE DE R&Eacute;ALISER VOS PLANS 
                            D&apos;EX&Eacute;CUTION DES TRAVAUX &Agrave; R&Eacute;ALISER
                        </span>
                    </div>
                    <span></span>
                </div>
                <span></span>
            </div>

            <div className="grid-page execution-sticky sticky-page top-minus-3">
                <div id="role-design-office-enterprise-support-software" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#10070;</span> <span>EX&Eacute;CUTION:</span>
                        <span>&#x2022;</span><span>PLAN D&apos;EX&Eacute;CUTION B&#194;TIMENT DU LOT &Eacute;LECTRICIT&Eacute; OU ECLAIRAGE PUBLIC</span>
                    </div>

                    <div id="role-design-office-enterprise-support">
                        <div>&#x2022;</div><div className="text-decoration-underline color-red">Le r&ocirc;le du Bureau d&apos;&Eacute;tudes Techniques en assistance aux entreprises :</div>
                        <div>&#x2022;</div><div>Les entreprises ex&eacute;cutantes r&eacute;pondent &agrave; des appels d&apos;offres en proposant un devis accompagn&eacute; d&apos;un m&eacute;moire technique pr&eacute;cisant le mat&eacute;riel pr&eacute;vu, les variantes propos&eacute;es, les moyens mat&eacute;riels et humains qui seront mis en œuvre ainsi qu&apos;un d&eacute;lai pour l&apos;ex&eacute;cution des travaux.</div>
                        <div>&#x2022;</div><div>Ensuite ils doivent r&eacute;aliser les plans d&apos;ex&eacute;cution, les sch&eacute;mas et les diverses notes de calcul, pour approbation par la maîtrise d&apos;œuvre et le bureau de contrôle.</div>
                        <div>&#x2022;</div><div>Le Bureau d&apos;&eacute;tudes BETIS peut intervenir, &agrave; la carte, sur chacune de ces phases, en assistance aux charg&eacute;s d&apos;affaires, dans le but d&apos;optimiser le chantier ou de r&eacute;pondre &agrave; une charge de travail trop &eacute;lev&eacute;e des charg&eacute;s d&apos;&eacute;tudes.</div>
                        <div>&#x2022;</div><div className="color-red">Normes NF C14-100 et NF C15-100 avec amendements &agrave; jour.</div>
                    </div>
                    <div id="execution-studies-software">
                        <div>&#x2022;</div><div className="font-weight-bold text-decoration-underline">Logiciels:</div>
                        <div></div><div>&Eacute;laboration des <span className="typography-key-word">plans d&apos;implantation</span> sous <span className="typography-key-word">AUTOCAD 2024</span>.</div>
                        <div></div><div>&Eacute;laboration des <span className="typography-key-word">notes de Calcul BT</span> + Sch&eacute;ma Unifilaires sous Le <span className="typography-key-word"></span>logiciel ECODIAL (Agr&eacute;&eacute; ELIE BT).</div>
                        <div></div><div>&Eacute;laboration de la <span className="typography-key-word">note d&apos;&eacute;clairage</span> sous <span className="typography-key-word">DIALUX EVO 12</span></div>
                    </div>

                    <div className="box-important-information width-40percent">PRINCIPAUX CLIENTS : ENTREPRISES ELECTRICIT&Eacute;</div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page execution-sticky sticky-page">
                <div id="public-lighting-execution-plan" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example">
                        <img src={PublicLightingExecutionPlan} alt="Plan d&apos;&eacute;cution d&apos;&eacute;clairage public" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box">
                <div id="electricity-lot-execution-plan" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example justify-self-right">
                        <img src={ElectricityLotExecutionPlan} alt="Plan d&apos;&eacute;cution lot &eactue;lectricit&eactue;" loading="lazy" />
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <div className="grid-page after-sticky-page execution-sticky sticky-page">
                <div id="electricity-lot-execution-plan-2" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example justify-self-left">
                        <img src={ElectricityLotExecutionPlan2} alt="Plan d&apos;&eacute;cution lot &eactue;lectricit&eactue;" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page execution-sticky sticky-page">
                <div id="electricity-lot-execution-plan-3" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example">
                        <img src={ElectricityLotExecutionPlan3} alt="Plan d&apos;&eacute;cution lot &eactue;lerage" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page execution-sticky sticky-page">
                <div id="electricity-lot-execution-plan-4" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example">
                        <img src={ElectricityLotExecutionPlan4} alt="Plan d&apos;&eacute;cution note de calcul BT" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="grid-page after-sticky-page colour-gradient-progressively-opened-box top-4rem">
                <div id="electricity-building-execution-studies-plan" className="grid-page-content">
                    <span></span>
                    <div className="title-1-with-bullet">
                        <span>&#10070;</span> <span>EX&Eacute;CUTION:</span>
                        <span>&#x2022;</span><span>PLAN D&apos;EX&Eacute;CUTION B&#194;TIMENT DU LOT &Eacute;LECTRICIT&Eacute; OU ECLAIRAGE PUBLIC</span>
                    </div>

                    <div id="transformation-post-connection-box">
                        <div className="text-decoration-underline font-weight-bold">Poste de transformation :</div>
                        <div>Un poste HTA/BT (ou poste de transformation HTA/BT) est un local, inaccessible au public, assurant la liaison entre le r&eacute;seau haute tension HTA (HTA)
                            et le r&eacute;seau basse tension (BT).
                        </div>
                        <div className="text-decoration-underline font-weight-bold">Coffret de coupure/branchement :</div>
                        <div>Cet &eacute;quipement permet de r&eacute;aliser l&apos;alimentation et la protection d&apos;un branchement individuel de forte puissance, ou le raccordement d'immeubles. Il
                            peut assurer &eacute;galement une fonction de coupure du r&eacute;seau basse tension et reçoit des fusibles jusqu&apos;&agrave; 200A ou des barrettes de sectionnement en
                            cuivre jusqu&apos;&agrave; 400A
                        </div>
                    </div>
                    <div id="NF-C-14100-calculs-rules-image" className="study-image-example width-50percent">
                        <img src={NFC14100CalculsRules} alt="R&egrave;gles de calculs NF C 14-100" loading="lazy" />
                    </div>
                    <span></span>
                </div>
                <div className="smartphone-landscape sticky-block"></div>
            </div>

            <div className="grid-page after-sticky-page">
                <div id="network-execution-plan" className="grid-page-content">
                    <span></span>
                    <div className="study-image-example study-big-image-example">
                        <img src={NetworkExecutionPlan} alt="Plan d&apos;&eacute;cution r&eactue;seau HTA/BT" loading="lazy" />
                    </div>
                    <span></span>
                </div>
            </div>
        </>
    )
}

export default ExecutionStudies;