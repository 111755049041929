import { Helmet, HelmetProvider } from "react-helmet-async";

function MetaTags({title, description, currentPageURL}) {
    return(
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={currentPageURL} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={currentPageURL} />
            </Helmet>
        </HelmetProvider>
    )
}

export default MetaTags;